import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Close } from '../assets/img/svg/close.svg'
import { ReactComponent as CircleX } from '../assets/img/svg/circle-x.svg'
function ShopCart({ setIsOpen }) {
  return (
    <>
      <div className='shop-cart-bg' onClick={() => setIsOpen()} />
      <div className='shop-cart-wrapper'>
        <div className='shop-cart'>
          <div className='shop-cart-close' onClick={() => setIsOpen()}>
            <Close />
          </div>
          <div className='shop-cart-top'>
            <div className='shop-cart-title'>Shopping Cart</div>
            <div className='shop-cart-items'>
              {/* <div className='shop-cart-item'>
                <div className='shop-cart-item-img'>
                  <img
                    src={require(`../assets/img/Upholstered-Sofa.jpg`)}
                    alt=''
                  />
                </div>
                <div className='shop-cart-item-box'>
                  <div className='shop-cart-item-box-info'>
                    <div className='shop-cart-item-box-info-title'>
                      Upholstered Sofa
                    </div>
                    <div className='shop-cart-item-box-info-price'>
                      <span>1</span>
                      <span>X</span>
                      $4,899.99
                    </div>
                  </div>
                </div>
                <div className='shop-cart-item-box-icon'>
                  <CircleX />
                </div>
              </div>
              <div className='shop-cart-item'>
                <div className='shop-cart-item-img'>
                  <img
                    src={require(`../assets/img/White-LED-Novelty-Floor-Lamp.jpg`)}
                    alt=''
                  />
                </div>
                <div className='shop-cart-item-box'>
                  <div className='shop-cart-item-box-info'>
                    <div className='shop-cart-item-box-info-title'>
                      White LED Novelty Floor Lamp
                    </div>
                    <div className='shop-cart-item-box-info-price'>
                      <span>1</span>
                      <span>X</span>
                      $243.99
                    </div>
                  </div>
                </div>
                <div className='shop-cart-item-box-icon'>
                  <CircleX />
                </div>
              </div> */}
            </div>
          </div>
          <div className='shop-cart-bottom'>
            <div className='shop-cart-subtotal'>
              Subtotal
              <span>$0.00</span>
            </div>
            <div className='shop-cart-btns'>
              <NavLink to='/cart' onClick={() => setIsOpen()}>
                Cart
              </NavLink>
              <NavLink to='/checkout' onClick={() => setIsOpen()}>
                Checkout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ShopCart }
